import { Icon, Paragraph, Popover } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { useTaxesAndFeesMessage } from './getTaxesAndFees';
import { usePriceQuoteUnit } from './usePriceQuoteUnit';
import { Pricing } from '@AuroraTypes';
import { BasketSummaryDetail } from '@Components/Checkout/Basket/BasketSummaryDetail/BasketSummaryDetail';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';

export type TotalPricePopoverProps = {
  pricing: Pick<Pricing, 'displayedPricingItems' | 'totalPaidAtHotel'>;
  localCurrency: string | undefined;
  mayHaveFees: boolean | undefined;
};

const showPricing = (totalPaidAtHotel: number, mayHaveFees?: boolean): boolean => {
  return totalPaidAtHotel > 0 || mayHaveFees == true;
};

export const TotalPricePopover: React.FC<TotalPricePopoverProps> = ({
  pricing,
  localCurrency,
  mayHaveFees,
}) => {
  const { t } = useTranslation();
  const { priceQuoteUnit } = usePriceQuoteUnit();

  const showDynamicPricingMessage = useFeatureFlag('ShowDynamicPricingMessage');

  const taxesAndFeesMessage = useTaxesAndFeesMessage(
    pricing.totalPaidAtHotel,
    mayHaveFees,
    localCurrency,
  );

  if (!showDynamicPricingMessage && !taxesAndFeesMessage) {
    return null;
  }

  return (
    <Popover
      content={
        <div
          sx={{
            padding: 'xs',
          }}
        >
          {showDynamicPricingMessage && (
            <Paragraph variant="extrasmall">
              {t('sellingTotalPricePopover.dynamicPricing')}
            </Paragraph>
          )}
          {showPricing(pricing.totalPaidAtHotel, mayHaveFees) && (
            <div
              sx={{
                paddingTop: showDynamicPricingMessage ? 'xs' : 'none',
                paddingBottom: 'xs',
              }}
            >
              {pricing.displayedPricingItems.map((pricingItem) => {
                return (
                  <BasketSummaryDetail
                    key={`BasketSummaryDetail-${pricingItem.label}`}
                    {...pricingItem}
                    loading={false}
                    priceQuoteUnit={priceQuoteUnit}
                    allowZeroPrice
                  />
                );
              })}
            </div>
          )}

          {taxesAndFeesMessage && (
            <Paragraph
              variant="extrasmall"
              sx={{
                color: 'textDimmedheavy',
              }}
            >
              {taxesAndFeesMessage}
            </Paragraph>
          )}
        </div>
      }
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: '4xs',
      }}
    >
      <Icon
        size="20"
        name="Markers/Tooltip"
      />
    </Popover>
  );
};
