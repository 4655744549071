import { useTranslation } from '@loveholidays/phrasebook';

import { useAppContext } from '@Contexts/contexts';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';

const getTranslationKey = (
  showTotal: boolean,
  includeFees: boolean,
  totalPaidAtHotel: number,
  mayHaveFees?: boolean,
): string | undefined => {
  if (includeFees && mayHaveFees) {
    return showTotal && totalPaidAtHotel > 0 ? 'taxesWithUnknownFees' : 'unknownFeesOnly';
  }

  return showTotal && totalPaidAtHotel > 0 ? 'taxesOnly' : undefined;
};

export const useTaxesAndFeesMessage = (
  totalPaidAtHotel: number,
  mayHaveFees?: boolean,
  localCurrency?: string,
): string | undefined => {
  const { t } = useTranslation();
  const { site, siteOverride } = useAppContext();
  const currency = siteOverride?.currency || site.currency;
  const showTotalIncludingPaidAtHotel = useFeatureFlag('ShowTotalIncludingPaidAtHotel');
  const includeFeesInPaidAtHotel = useFeatureFlag('IncludeFeesInPaidAtHotel');

  if (!showTotalIncludingPaidAtHotel && !includeFeesInPaidAtHotel) {
    return;
  }

  const resolvedLocalCurrency: string =
    localCurrency || t('sellingTotalPricePopover.localCurrencyFallback');
  const endOfTranslation = resolvedLocalCurrency === currency ? 'core' : 'foreignCurrency';

  const translationKey = getTranslationKey(
    showTotalIncludingPaidAtHotel,
    includeFeesInPaidAtHotel,
    totalPaidAtHotel,
    mayHaveFees,
  );

  if (!translationKey) {
    return;
  }

  if (translationKey === 'unknownFeesOnly') {
    return t(`sellingTotalPricePopover.taxesAndFeesMessages.${translationKey}.core`);
  }

  return t(`sellingTotalPricePopover.taxesAndFeesMessages.${translationKey}.${endOfTranslation}`, {
    localCurrency: resolvedLocalCurrency,
    targetCurrency: site.currency,
  });
};
